<script setup lang="ts">
import UserChip from '@/components/utils/UserChip.vue';
import type { ReviewCardType, ToProps } from '../types';
import Link from '@/components/utils/Link.vue';
import {useI18n} from "vue-i18n";
const {t} = useI18n({
  useScope: 'global'
});
defineProps<ToProps<ReviewCardType>>()

</script>

<template>
  <div class="border-2 border-brand-stroke/10 rounded-3 p-6 flex flex-col items-center min-w-64 max-w-64">
    <div class="flex items-center gap-x-2.5">
      <UserChip 
        :name="name"
        :profile-picture="profilePicture"
      />
      <div class="text-xs font-bold text-brand-gradient">
        {{ name }}
      </div>
    </div>
    <p class="text-brand-secondary leading-[150%] text-center mt-4">
      <slot name="description"/>
    </p>
    <span class="i-dipsway-quotes bg-brand-gradient text-10 inline-block mt-6" />
    <div class="mt-7 flex items-center gap-x-1">
      <Link
        :href="source.href"
        size="sm"
      >
        {{ t('view_on') }} {{ source.platform }}
      </Link>
      <!-- <a 
      
      class="text-xs "
      >
        View on {{ source.platform }}
      </a>
      <span class="i-uil:arrow-up-right mt-.5" /> -->
    </div>
  </div>
</template>