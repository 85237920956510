<script setup lang="ts">
import ReviewCard from "./cards/ReviewCard.vue";
import Swiper from "swiper";
import { Autoplay } from "swiper/modules";
import { onMounted, ref } from "vue";
import TweetCard from "./cards/TweetCard.vue";
import type { ReviewCardType, TweetCardType } from "./types";
import useAosRefresh from "@/composables/useAosRefresh";

useAosRefresh()

const cards: (ReviewCardType | TweetCardType)[] = [
  {
    type: "review",
    name: "Alessandro C.",
    source: {
      platform: "SourceForge",
      href: "https://sourceforge.net/software/product/DipSway/#review-651ff6f8fda2b8ef56d3ff2f",
    },
    description:
      `"I average a 4-5% return monthly on trades. Also, great team." - Every month till now my bot closed trades with an average of 4-5% return. Some trades are ~1%+ and some are around 9-10%+. The best feeling is to wake up with profitable trades. I confirm the bot does what I paid it to do. No complaints there. PERSONAL BONUS: After contacting the team for a...`,
  },
  {
    type: "review",
    name: "Giulio Scagnolari",
    source: {
      platform: "TrustPilot",
      href: "https://www.trustpilot.com/reviews/6551e8a3f960a367a660c643"
    },
    description: `"DipSway rocks!" - I don't have much experience with cryptocurrencies, and that's precisely why I was looking for something that would allow me to invest some money without the risk of making mistakes. Here's where the real advantage of a tool like this lies: by entrusting it with transactions, it does everything on its own, always trying to secure the best profit or the least loss! You can choose from a variety of different cryptocurrencies, and the website is very clear, complete with charts and statistics! Continuously improving! Well done, 5 stars!`
  },
  {
    type: "review",
    name: "Marco Frömbgen",
    profilePicture: '/testimonials/profiles/marco_frombgen.jpeg',
    source: {
      platform: "ProductHunt",
      href: "https://www.producthunt.com/posts/dipsway-2?comment=2882485",
    },
    description:
      "I've been following this project for multiple months and am pleasantly surprised by my experience. I think that it is one of the best projects in that space for quite some time. Of course, nothing is perfect but the support and open communication does make a difference in that case. Additionally, I witnessed the rollout of many awesome features to the platform over time. I hope that the team keeps up the good work 🚀",
  },
  {
    type: "tweet",
    name: "LλURA-VΞRSΞ",
    twitterHandle: "Laura__crypto",
    tweet: {
      href: 'https://x.com/Laura__crypto/status/1724371825478525071?s=20'
    },
    description: `DipSway, an ALL IN ONE trading automation platform for #CRYPTO. CONNECT to the powerful AI trading BOT, Supports 5+ exchanges and can trade 315+ #CRYPTO Simultaneously 🤯🤯. \nPowered by a Neural #Network Trading has never been easier 📈🚀 http://dipsway.com`,
    profilePicture: '/testimonials/profiles/laura__verse.jpg'
  },
  {
    type: "review",
    name: "Remeriot",
    source: {
      platform: "TrustPilot",
      href: "https://www.trustpilot.com/reviews/65ca52d69717acb1a259d1d4"
    },
    description: `"Best Trading Bot In The Game !" - The only bot you need. Great website, wonderful experience even for a newbie, easy to set up and control. It's improving each day because the support is listening and responding fast, I've never seen anything like this before ! It's super efficient even when you don't have a lot of capital, it's running great and everything is green like you have night vision. The price of the different plans are really affordable and the team is really amazing!...`
  },
  {
    type: "review",
    name: "Zack V.",
    source: {
      platform: "SourceForge",
      href: "https://sourceforge.net/software/product/DipSway/#review-64f228b9c49a54c8c76248a0",
    },
    description:
      `"12% growth in 2 months. Best bot platform I tried" - The bot makes great trades, the platform is nice and very easy to navigate. And I haven't manually traded since I started my subscription The support is really good too!`,
  },
  {
    type: "review",
    name: "Tino",
    profilePicture: '/testimonials/profiles/tino.png',
    source: {
      platform: "TrustPilot",
      href: "https://www.trustpilot.com/reviews/65465d3b037a5ed45ee20023"
    },
    description: `Enjoying DipSway really well. The team is great ans really helpful. There is like bo waiting for support and they always taking your concerns serious. Since i joined this project, they made huge progress. Bot is far more productive, UI got a huge overhaul and is now really neat. Huge shout-out to the team. Thank you guys.`
  },
  {
    type: "tweet",
    name: "DipSway",
    profilePicture: '/testimonials/profiles/dipsway.png',
    description: `🚀 Be part of the success story! Witness the power of AI in crypto trading as we achieve +7.6% profit on #EOS/USDT in just 1d ~47s ago. Ready to ride the wave? 🌊🚀 #crtptocurrency #Cryptofam #CryptoNews #altcoin #price`,
    twitterHandle: 'DipSway_bot',
    tweet: {
      href: 'https://x.com/DipSway_bot/status/1716636289645035968?s=20',
      image: {
        src: '/testimonials/tweets/dipsway-eos-usdt.png',
        alt: 'EOS/USDT trade chart'
      }
    }
  },
  {
    type: "tweet",
    name: "DipSway",
    profilePicture: '/testimonials/profiles/dipsway.png',
    description: `📈 Just closed another fantastic trade - profits streak! +6.2% profit on ATOM/USDT in just 21h ~1 days ago💰 #CryptoInvesting #AIcryptobot`,
    twitterHandle: 'DipSway_bot',
    tweet: {
      href: 'https://x.com/DipSway_bot/status/1711783404625072400?s=20',
      image: {
        src: '/testimonials/tweets/dipsway-atom-usdt.png',
        alt: 'ATOM/USDT trade chart'
      }
    }
  },
  {
    type: "review",
    name: "Paul Gorval",
    profilePicture: '/testimonials/profiles/paul_gorval.jpeg',
    source: {
      platform: "ProductHunt",
      href: "https://www.producthunt.com/products/dipsway?comment=2873762#dipsway-2"
    },
    description: `Phenomenal features and the focus on easing crypto trading for the average user is spot on!`
  },
  {
    type: 'review',
    name: 'Alessandro Casarin',
    source: {
      platform: 'TrustPilot',
      href: "https://www.trustpilot.com/users/65302da2f70f810012ae0328"
    },
    description: `I picked a yearly plan and I can confirm that the AI bot does what it is intended to do. It's performing well for me. The platform is great and easy to navigate. Great overall.`
  },
  {
    type: "review",
    name: "Phil",
    source: {
      platform: "TrustPilot",
      href: "https://www.trustpilot.com/reviews/659c63db90368fc4f1d0f269"
    },
    description: `"Superb" - Very simple to use bot even for a beginner like myself. Based on the result of it's work I can see that it's working good and does what it's meant to. So far I have no issues with it. Something I'd like to point out is that the customer service is absolutely superb. Whenever you have a question you actually get assisted by one of the developers and not some kind of bot (lol) or tired customer service assistant. I'm happy and I do recommend the bot to anyone who's interested in trying this.`
  },
  {
    type: "tweet",
    name: "Builda Protocol ✨",
    description: `Project Spotlight: @DipSway_bot 🌊 \n\n With the ability to connect 5+ exchanges, DipSway is providing the all-in-one tool for automated AI trading across hundreds of tokens. \n\n Check their demo if you want to see what it's capable of! \n\n https://builda.dev/list/dip-sway`,
    twitterHandle: 'BuildaProtocol',
    tweet: {
      href: 'https://x.com/builda_official/status/1726278765146525914?s=20'
    },
    profilePicture: '/testimonials/profiles/buildaprotocol.png'
  },
  {
    type: "review",
    name: "Grant D.",
    source: {
      platform: "SourceForge",
      href: "https://sourceforge.net/software/product/DipSway/#review-64db471e15accdb80779eea9",
    },
    description:
      `"User friendly AI Bot with look like it could be the one!" - Excited about what could come as the promise of AI trading is an exciting one. Will try support and use the product as long as I can, as I can see the good intentions in the team and product development.`,
  },
  {
    type: "review",
    name: "Yorsan Tsanov",
    profilePicture: '/testimonials/profiles/yordan_tsanov.png',
    source: {
      platform: "TrustPilot",
      href: "https://www.trustpilot.com/reviews/658de8dc40a890dc747f1df3"
    },
    description: `"DipSway delivers what it promises to deliver" - Dip Sway is excellent because it actively tries to deliver on what it promises: a profitable crypto bot that acts on data. I am happy with the bots' returns, as well as the technical support I received from the Customer Success Manager of the project: Luca Chiarra. Thank you guys, I remain your active customer and am looking forward to seeing the further developments set in the business' roadmap.`
  },
  {
    type: "tweet",
    name: "DipSway",
    profilePicture: '/testimonials/profiles/dipsway.png',
    description: `📊 Our users' bots are on fire! 🔥 +35% profit on OAX/USDT in just 53h ~2 days ago. Profits are pouring in as we speak. #CryptoInvesting #AIcryptobot`,
    twitterHandle: 'DipSway_bot',
    tweet: {
      href: 'https://x.com/DipSway_bot/status/1714377247614783805?s=20',
      image: {
        src: '/testimonials/tweets/dipsway-oax-usdt.png',
        alt: 'OAX/USDT trade chart'
      }
    }
  },
  {
    type: "review",
    name: "A DipSway User",
    source: {
      platform: "SourceForge",
      href: "https://sourceforge.net/software/product/DipSway/#review-651fe73a4b41f0721a3145b5",
    },
    description:
      `"1st month I made half of the Yearly Trader subscription. New star in the algorithmic crypto space." - Really good ROI, my trades are usually around 2-3%. I saw a few of 12%+ also so that was nice.`,
  },
  {
    type: "review",
    name: "Axel",
    profilePicture: '/testimonials/profiles/axel.png',
    source: {
      platform: "TrustPilot",
      href: "https://www.trustpilot.com/reviews/6599a08440f1e70871a21435"
    },
    description: `"It works!" - To be honest, I wasn't sure before signing up, but I read recommendations from people who had been trying it for several weeks with positive results and I went with the most basic plan. I have only been on it for a short time, but so far everything is working very well and I am on profit. Next month I will renew to a higher plan. To highlight the technical service, very attentive and quick with the answers to any questions. I recommend it.`
  },
  {
    type: "tweet",
    name: "DipSway",
    profilePicture: '/testimonials/profiles/dipsway.png',
    description: `🤖 Another day, another win! Our users' AI crypto trading bots just closed a jaw-dropping trade. +6.0% profit on STPT/USDT in just 23h ~1 days ago💰 Ready to boost your profits? Grab your AI bot! 🚀 #CryptoInvesting #AIcryptobot`,
    twitterHandle: 'DipSway_bot',
    tweet: {
      href: 'https://x.com/DipSway_bot/status/1714295653487300980?s=20',
      image: {
        src: '/testimonials/tweets/dipsway-stpt-usdt.png',
        alt: 'STPT/USDT trade chart'
      }
    }
  },
  {
    type: "review",
    name: "Dylan",
    source: {
      platform: "TrustPilot",
      href: "https://www.trustpilot.com/reviews/657395cd6fccb3729f160516"
    },
    description: `"So far so good" - So far so good. Does what it says. Been using it for several months and the experience has been good so far.`
  },
];

const swiperElRef = ref<HTMLDivElement>();

onMounted(() => {

  const swiperEl = swiperElRef.value;
  if (!swiperEl) return;

  new Swiper(swiperEl, {
    modules: [Autoplay],
    loop: true,
    slidesPerView: "auto",
    simulateTouch: true,
    speed: 10000,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    }
  })

})

</script>

<template>
  <section
    data-aos
    id="testimonials" 
    :class="[
      'w-full',
      'duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0)'
    ]"
  >
    <div 
      ref="swiperElRef" 
      class="swiper w-full"
    >
      <div class="swiper-wrapper ease-linear w-max flex space-x-12">
        <div 
          v-for="(card, index) in cards" 
          :key="index" 
          class="swiper-slide"
        >
          <ReviewCard
            v-if="card.type === 'review'"
            v-bind="{
              name: card.name,
              profilePicture: card?.profilePicture,
              source: {
                platform: card.source.platform,
                href: card.source.href,
              },
            }"
          >
            <template #description>
              {{ card.description }}
            </template>
          </ReviewCard>
          <TweetCard
            v-else-if="card.type === 'tweet'"
            v-bind="{
              name: card.name,
              profilePicture: card.profilePicture,
              twitterHandle: card.twitterHandle,
              tweet: card.tweet
            }"
          >
            <template #description>
              {{ card.description }}
            </template>
          </TweetCard>
        </div>
      </div>
    </div>
  </section>
</template>
