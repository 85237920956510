<script setup lang="ts">
import UserChip from '@/components/utils/UserChip.vue';
import type { ToProps, TweetCardType } from '../types';
import {useI18n} from "vue-i18n";
const {t} = useI18n({
  useScope: 'global'
});
defineProps<ToProps<TweetCardType>>()

// const twitterProfileUrl = `https://x.com/${props.twitterHandle}`

</script>

<template>
    <div class="p-6 flex flex-col border-brand-primary border-2 items-center min-w-64 max-w-64 bg-brand-background rounded-3 relative">
      <div class="flex items-center gap-x-2.5">
        <UserChip 
          :name="name"
          :profile-picture="profilePicture"
        />
        <div class="text-xs font-bold">
          <span class="block text-brand-gradient" >
            {{ name }}
          </span>
          <span
            class="block text-white opacity-60 hover:opacity-100 duration-500 ease-in-out" 
          >
            @{{ twitterHandle }}
          </span>
        </div>
      </div>
      <p class="text-brand-secondary leading-[150%] text-center mt-4">
        <slot name="description"/>
      </p>
      <div 
        v-if="tweet?.image"
        class="w-full aspect-square rounded-4 my-4 relative flex items-center justify-center overflow-hidden border-2 border-brand-stroke/10" 
      >
        <img
          class="h-full w-full object-contain"
          :src="tweet.image.src"
          :alt="tweet.image.alt"
          loading="lazy"
        />
      </div>
      <div class="flex items-center gap-x-1 mt-4">
        <a 
          :href="tweet.href"
          target="_blank"
          class="text-xs"
        >
          <span class="absolute inset-0 z-100" />
          <span class="i-mage:x h-6 w-6 bg-white inline-block" />
          <span class="sr-only" >
            {{ t('go_to_tweet') }}
          </span>
        </a>
      </div>
    </div>
</template>